
.header{
   
    display: flex;
    align-items: center;
    background-color:#021430 ;
    height: 16%;
    padding: 8px 8px;
    color: white;
    justify-content: space-between;

}
.header > .logo {
    height: 120px;
    margin:6px 6px;
   background-color: white 

}
.header--title{
    font-size: 18px;
    color: white;
}

@media   screen and (min-height: 48px) {
    .header--title {
      font-size: 6px;
    }
    .header > .logo {
        height: 10px;
        margin:6px 6px;
    
    }
}
@media   screen and (min-height: 64px) {
    .header--title {
      font-size: 8px;
    }
    .header > .logo {
        height: 10px;
        margin:2px 2px;
    
    }
   
  }

  @media   screen and (min-height: 96px) {
    .header--title {
      font-size: 12px;
    }
    .header > .logo {
        height: 12px;
        margin:6px 6px;
    
    }
   
  }

  @media   screen and (min-height: 128px) {
    .header--title {
      font-size: 14px;
    }
    .header > .logo {
        height: 14px;
        margin:6px 6px;
    
    }
   
  }
@media   screen and (min-height: 192px) {
    .header--title {
      font-size: 12px;
    }
    .header > .logo {
        height: 20px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 256px) {
    .header--title {
      font-size: 12px;
    }
    .header > .logo {
        height: 22px;
        margin:6px 6px;
    
    }
   
  }

  @media   screen and (min-height: 320px) {
    .header--title {
      font-size: 24px;
    }
    .header > .logo {
        height: 38px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 384px) {
    .header--title {
      font-size: 24px;
    }
    .header > .logo {
        height: 30px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 448px) {
    .header--title {
      font-size: 28px;
    }
    .header > .logo {
        height: 30px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 512px) {
    .header--title {
      font-size: 30px;
    }
    .header > .logo {
        height:34px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 600px) {
    .header--title {
      font-size: 28px;
    }
    .header > .logo {
        height: 34px;
        margin:6px 6px;
    
    }
   
  }
  @media   screen and (min-height: 700px) {
    .header--title {
      font-size: 28px;
    }
    .header > .logo {
        height: 34px;
        margin:6px 8;
    
    }
   
  }
  @media   screen and (min-height: 800px) {
    .header--title {
      font-size: 28px;
    }
    .header > .logo {
        height: 36px;
        margin:6px 6px;
    
    }
   
  }

  .string {
    transition: opacity 1s ease-in-out;
  }
  
  .hidden {
    opacity: 0;
  }
  
  .visible {
    opacity: 1;
  }