.noTrip{
    font-size: 20px
}

@media   screen and (min-height: 48px) {
    .noTrip {
      font-size: 6px;
    }
  
   
  }
  @media   screen and (min-height: 64px) {
    .noTrip {
      font-size: 7px;
    }
  
   
  }


  @media   screen and (min-height: 96px) {
    .noTrip {
      font-size: 8px;
    }
  
   
  }

  @media   screen and (min-height: 128px) {
    .noTrip {
      font-size: 16px;
    }
   
   
  }
@media   screen and (min-height: 192px) {
    .noTrip {
      font-size: 20px;
    }
 
  }
  @media   screen and (min-height: 256px) {
    .noTrip{
      font-size: 24px;
    }
 
   
  }

  @media   screen and (min-height: 320px) {
    .noTrip{
      font-size: 26px;
    }
  
   
  }
  @media   screen and (min-height: 384px) {
    .noTrip {
      font-size: 28px;
    }
  
   
  }
  @media   screen and (min-height: 448px) {
    .noTrip{
      font-size: 30px;
    }
 
   
  }
  @media   screen and (min-height: 512px) {
    .noTrip {
      font-size: 32px;
    }
 
   
  }
  @media   screen and (min-height: 600px) {
    .noTrip{
      font-size: 34px;
    }
 
   
  }
  @media   screen and (min-height: 700px) {
    .noTrip{
      font-size: 36px;
    }
 
   
  }
  @media   screen and (min-height: 800px) {
    .noTrip {
      font-size: 36px;
    }
 
  }