/* src/HorizontalScrollMenu.css */
.scroll-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  white-space: nowrap;
  cursor: grab;
  position: fixed;
  top: 0.1;
  left: 10;
  background-color: #06133f; /* Background color of the bar */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for the bar */
  z-index: 1000; /* Ensures it stays on top of other elements */
  padding: 10px 0; /* Adds some padding to the bar */
  scrollbar-width: none;  
 /* Hides scrollbar for Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Hides scrollbar for Chrome, Safari, and Opera */
  
}

.scroll-content {

  display: flex;
  user-select: none;
}

.scroll-item {
  flex: 0 0 auto;
  width: 150px; /* Adjust width as needed */
  margin: 0 10px; /* Space between items */
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%); /* Gradient background */
  color: white; /* Text color */
  border-radius: 15px; /* Rounded corners */
  text-align: center;
  line-height: 50px; /* Adjust based on item height */
  font-size: 16px; /* Font size */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for items */
  transition: transform 0.2s, background 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
  padding: 20px; /* Adds padding inside the item */
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-item:hover {
  transform: scale(1.05); /* Slightly enlarges the item on hover */
  background: linear-gradient(135deg, #2575fc 0%, #6a11cb 100%); /* Reversed gradient on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
}


.scroll-item:hover {
  transform: scale(1.05); /* Slightly enlarges the item on hover */
  background: linear-gradient(135deg, #2575fc 0%, #6a11cb 100%); /* Reversed gradient on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
}

.scroll-item.active {
  background: linear-gradient(135deg, #ff416c 0%, #ff4b2b 100%); /* Different gradient for active item */
  transform: scale(1.1); /* Slightly larger scale for active item */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.25); /* Larger shadow for active item */
}

.scroll-container:active {
  cursor: grabbing;
}
.content {
  padding-top: 120px; /* Adjust based on the height of your scroll menu */
}