.string-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust based on your container size */
    position: relative;
    width: 90%;
  }
  
  .string {
    position: absolute;
    transition: opacity 1s ease-in-out;
    font-size: 18px;
  }
  
  .hidden {
    opacity: 0;
  }
  
  .visible {
    opacity: 1;
  }