body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.chooseAgency{
  width: 100%;
  display: flex;
  gap:32px;
  overflow-x: auto;
  scrollbar-color: transparent transparent;
  user-select: none;
  background-color: rgba(158, 203, 255, 0.123);
  }
  .chooseAgency::-webkit-scrollbar{
  height: 1px;
  background-color: transparent;
  }
  .chooseAgency .agency{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  }
  .chooseAgency .agency .agencyImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  }
  .chooseAgency .agency .agencyImage img{
  width:100%;
  height: 100%;
}
.chooseAgency .agency .agencyName{
font-size: 13px;
font-weight: 400;
color: black;
}