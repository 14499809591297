/* TextToSpeechUI.css */

/* Overall page container */
.tts-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* Card container styling for desktop and larger screens */
.tts-card, .auto-text-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

/* Auto text card styling */
.auto-text-card {
    text-align: center;
    border: 1px solid #e0e0e0;
}

/* Text paragraph within the auto text card */
.auto-text-card p {
    font-size: 1.1em;
    color: #333;
    margin: 15px 0;
}

/* Header styling */
h1, h2 {
    color: #333;
    font-family: Arial, sans-serif;
    font-size: 1.5em;
    text-align: center;
}

/* Textarea styling */
.tts-textarea {
    width: 100%;
    font-size: 1em;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
    overflow: hidden;
}
/* Button group styling */
.button-group {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
    width: 100%; /* Ensure buttons take full width of the container */
}
/* Button Group Styling */
.button-group {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
    width: 100%; /* Ensure button group takes full width */
}

/* Button Styling */
.tts-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 1em;
    flex: 1;
    text-align: center;
    display: inline-block; /* Ensure button remains visible */
}

/* Button Hover */
.tts-button:hover {
    background-color: #0056b3;
}

/* Responsive Styling */
@media (max-width: 851px) {
    .button-group {
        flex-direction: column; /* Stack buttons vertically on small screens */
        gap: 8px;
    }

    .tts-button {
        width: 100%; /* Full width for mobile */
        font-size: 0.9em;
        padding: 8px 16px;
        display: block; /* Ensure button stays visible */
    }
}

@media (max-width: 480px) {
    .tts-button {
        font-size: 0.85em;
        padding: 10px;
    }
}

/* Overlay for loading spinner */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Spinner animation */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive styles */
@media (max-width: 768px) {
    .tts-card, .auto-text-card {
        padding: 15px;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
        width: 90%;
        margin-bottom: 15px;
    }
    
    h1, h2 {
        font-size: 1.2em;
    }

    .tts-textarea {
        font-size: 0.9em;
    }

    .tts-button {
        font-size: 0.9em;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .tts-card, .auto-text-card {
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
    }

    h1, h2 {
        font-size: 1em;
    }

    .tts-textarea {
        font-size: 0.85em;
        padding: 8px;
    }

    .tts-button {
        font-size: 0.85em;
        padding: 6px 12px;
    }
}
.sounddownload{
color: blue;
}