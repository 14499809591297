/* SelectComponent.css */
.custom-select-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.custom-select__control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-size: 16px;
  background-color: white;
  transition: border-color 0.3s ease;
}

.custom-select__control--is-focused {
  border-color: #007bff;
  box-shadow: 0 0 0 1px #007bff;
}

.custom-select__menu {
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.custom-select__option {
  padding: 10px;
}

.custom-select__option--is-selected {
  background-color: #007bff;
  color: white;
}

.custom-select__option--is-focused {
  background-color: #e6f7ff;
}